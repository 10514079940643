

import SaveButton from "../save-button.vue";
import modals from "../../mixins/modals";

export default {
    name: "AddEventQuestionsModal",
    components: {SaveButton},
    mixins: [modals],
    props: {
        company: {
            type: Object,
            default: null,
        },
        questionId: {
            type: Number,
            default: null,
        },
        defaultQuestion: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            answers: [],
            answer: {
                answer: null,
                question: null,
                company: null,
                createdByUser: null,
            },
            eventQuestion: {
                question: null,
                company: null,
                createdByUser: null,
                answer: null,
            },
            overrideQuestion: {
                question: null,
                company: null,
                createdByUser: null,
                overridingQuestion: null,
                answer: null,
            },
            originalQuestion: null,
        }
    },
    computed: {},

    mounted() {
        const modalElement = this.$refs.modal;
        // Add event listener for Bootstrap's hidden.bs.modal event
        modalElement.addEventListener('hidden.bs.modal', this.resetModalFields);

        this.initializeData();
    },
    watch: {
        questionId: {
            immediate: true,
            handler() {
                this.initializeData(); // Re-fetch when questionId changes
            },
        },
    },
    methods: {
        initializeData() {
            // case existing default question
            if (this.questionId && this.defaultQuestion) {
                // Fetch question details if questionId is provided
                this.$axios.get(`/api/question_for_events/${this.questionId}`).then((response) => {
                    this.eventQuestion = response.data;
                    this.answers = this.eventQuestion.answers;
                    this.answers.forEach((answer) => {
                        if (answer.company.id === this.company.id) {
                            this.eventQuestion.answer = answer.answer;
                        }
                    });
                }).catch((error) => {
                    console.error("Failed to fetch question:", error);
                });

                // case existing question, but not default question
            } else if (this.questionId && !this.defaultQuestion) {
                // Fetch question details if questionId is provided
                this.$axios.get(`/api/question_for_events/${this.questionId}`).then((response) => {
                    this.eventQuestion = response.data;
                }).catch((error) => {
                    console.error("Failed to fetch question:", error);
                });
                // case new and not-default question
            } else {
                this.eventQuestion = {
                    question: null,
                    company: this.company ? this.company['@id'] : null,
                    createdByUser: `/api/users/${this.userInfo.id}`,
                };
            }
        },
        submitForm() {
            if (this.questionId && this.defaultQuestion) {
                this.overrideQuestion.question = this.eventQuestion.question;
                this.overrideQuestion.createdByUser = this.eventQuestion.createdByUser;
                this.overrideQuestion.company = this.company['@id'];
                this.overrideQuestion.overridingQuestion = this.eventQuestion['@id'];
                this.$axios.post("/api/question_for_events", this.overrideQuestion).then((response) => {
                    this.answer.answer = this.eventQuestion.answer
                    this.answer.question = response.data['@id']
                    this.answer.company = this.company['@id']
                    this.answer.createdByUser = this.eventQuestion.createdByUser
                    this.$axios.post("/api/answer_for_event_questions", this.answer).then((response) => {
                        this.$emit('questions-added');
                    })

                    this.$emit('questions-added');
                })
            } else if (this.questionId && !this.defaultQuestion) {
                this.eventQuestion.company = this.company['@id'];
                this.$axios.patch(
                    `/api/question_for_events/${this.eventQuestion.id}`,
                    this.eventQuestion,
                    {
                        headers: {
                            'Content-Type': 'application/merge-patch+json',
                        },
                    }
                ).then((response) => {
                    this.$emit('questions-added');
                });
            } else {
                this.eventQuestion.company = this.company['@id'];
                this.$axios.post("/api/question_for_events", this.eventQuestion).then((response) => {
                    this.$emit('questions-added');
                })
            }

        },
        resetModalFields() {
            this.$emit('reset-fields'); // Notify the parent to reset the fields
        },
    }
}
