
import LoadingSpinner from "../loading-spinner.vue";

export default {
    name: "AutosaveCellAnswer",
    components: {LoadingSpinner},
    props: {
        initialValue: {
            type: String,
            required: false,
            default: ""
        },
        questionId: {
            type: Number,
            required: true
        },
        companyId: {
            type: Number,
            required: true
        },
        answerId: {
            type: Number,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            originalValue: null,
            value: null,
            allowUpdates: false,
            loading: false,
            answer: null,
        };
    },
    watch: {},
    async created() {
        this.value = this.initialValue;
        await this.$nextTick();
        this.allowUpdates = true;
        this.adjustHeight(); // Adjust height initially if there's pre-filled text
    },
    methods: {
        adjustHeight() {
            const textarea = this.$refs.textarea;
            if (textarea) {
                textarea.style.height = "auto"; // Reset height
                textarea.style.height = `${textarea.scrollHeight}px`; // Set height to match content
            }
        },
        submitUpdate() {
            if (!this.allowUpdates || this.value === this.originalValue) {
                return;
            }

            this.loading = true;

            if (this.answerId) {
                this.$axios.patch(`/api/answer_for_event_questions/${this.answerId}`,
                    {
                        answer: this.value
                    },
                    {
                        headers: {'Content-Type': 'application/merge-patch+json'},
                    }
                ).catch(() => {
                    this.$emit("error");
                }).finally(() => {
                    this.loading = false;
                });
            } else {
                this.$axios.post(`/api/answer_for_event_questions`, {
                    answer: this.value,
                    question: `/api/question_for_events/${this.questionId}`,
                    company: `/api/companies/${this.companyId}`,
                    createdByUser: `/api/users/${this.userInfo.id}`
                }).catch(() => {
                    this.$emit("error");
                }).finally(() => {
                    this.loading = false;
                    this.$emit("refresh");
                });
            }
        }
    },
};
