


import EditCompanyGroupModal from "../company/edit-company-group-modal.vue";
import EditContactListModal from "../emails/edit-contact-list-modal.vue";
import modals from "../../mixins/modals";

export default {
    name: "GroupsCard",
    components: {EditContactListModal, EditCompanyGroupModal},
    mixins: [modals],
    props: {
        companyGroups: {
            type: Array,
            default: () => []
        },
        contactGroups: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            groupToEdit: null,
            editModal: null,
            baseurl: null,
        }
    },

    mounted() {
        this.baseurl = window.location.origin;
    },
    methods: {
        openCompanyGroupModal(id) {
            this.groupToEdit = id;
            this.editModal = this.openModal(this.$refs.editCompanyGroupModal);
        },
        openContactListModal(id) {
            this.groupToEdit = id;
            this.editModal = this.openModal(this.$refs.editContactListModal);
        },
        closeEditModal() {
            this.closeModal(this.editModal);
        },
    },
}


